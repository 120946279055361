const bech32 = require('bech32');
const WAValidator = require('multicoin-address-validator');
import { isAddress, toChecksumAddress } from 'web3-utils';

export const verifyAddress = {
  multiAddress(platform, address) {
    if (platform === 'TADA') {
      platform = 'ADA';
    }

    if (platform == 'ATOM') {
      return verifyCosmosAddress(address);
    }

    if (platform == 'IRIS') {
      return verifyIrisAddress(address);
    }

    if (platform == 'ETH') {
      return verifyEthAddress(address);
    }

    const valid = WAValidator.validate(address, platform.toLowerCase(), 'testnet') || WAValidator.validate(address, platform.toLowerCase());

    return valid;
  }
};


const verifyCosmosAddress = (address) => {
  try {
    const result = bech32.decode(address.toLowerCase());
    return result.prefix == 'cosmos';
  }
  catch (e) {
    console.log(e);
    return false;
  }
};

const verifyIrisAddress = (address) => {
  try {
    const result = bech32.decode(address.toLowerCase());
    return result.prefix == 'iaa';
  }
  catch (e) {
    console.log(e);
    return false;
  }
};

const verifyEthAddress = (address) => {
  try {
    if (!isAddress(address)) {
      return false;
    }
    return toChecksumAddress(address);
  } catch (e) {
    console.log(e);
    return false;
  }
};

export default verifyAddress;
